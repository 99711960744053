
import { API } from '@aws-amplify/api';
import {Auth} from '@aws-amplify/auth';
import { PubSub } from 'aws-amplify';
import {Amplify} from '@aws-amplify/core';
// import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { v4 as uuidv4 } from 'uuid';


export const apiPaths = {
  requestControl:"/requestControl",
  payment:"/payment",
  paymentIntent:"/paymentIntent",
  driveWaitlist:"/driveWaitlist",
  gameWaitlist:"/gameWaitlist",
  cancelDrive:"/cancelDrive",
  createProduct:"/createProduct",
  roverUnits:"/roverUnits",
  newCreatorAccount:"/creator/account/create",
  purchaselink:"/purchase/link",
  purchaseTrial:"/purchase/trial",
  connectToRoverMsg:"/rover/message/connect",
  addRover:"/rover/add",
  updateRoverEnvironment:"/rover/updateEnvironment",
  archiveProduct:"/product/archive",
  joinRoverEnvironmentWaitlist:"/roverEnvironment/joinWaitlist",
  roverEnvironmentSetupPubsub:"/roverEnvironment/setupPubsub",
  turretStoreVerifyUser:"/turretStore/verifyUser",
  turretStoreGetDepositLink:"/turretStore/getDepositLink",
  turretVoteVoterInfo:"/turretVote/getVoterInfo",
  turretVoteVote:"/turretVote/vote",
  turretVoteFindStreamer:"/turretVote/findStreamer",
  turretVoteSubmitAddress:"/turretVote/submitAddress",
  turretVoteJoinWaitlist:"/turretVote/joinWaitlist",
  subscribeToList:"/subscribeToList"
}


export const DriveState = {
  unauth: "unauth",
	viewing: "viewing",
	requested: "requested",
	ready: "ready",
	driving: "driving"
}


export function turretVoteSubmitAddress(token, personName, addressLine1, addressLine2, city, state, zipcode, country, phone) {


  var body = {
    token: token,
    name: personName,
    addressLine1: addressLine1,
    addressLine2: addressLine2,
    city: city,
    state: state,
    zipcode: zipcode,
    country: country,
    phone: phone,
    contestId:"8c15c6fd-a018-4133-b0f5-537345698bf2"
    
  }
  console.log("turretVoteSubmitAddress",body)

  return apiPostPromiseUnauth(body, apiPaths.turretVoteSubmitAddress)

}

export function turretVoteFindStreamer(streamerName) {


    var body = {
      streamerName: streamerName,
      contestId:"8c15c6fd-a018-4133-b0f5-537345698bf2"
    }
    console.log("turretVoteFindStreamer",body)

    return apiPostPromiseUnauth(body, apiPaths.turretVoteFindStreamer)

}

export function turretVoteVote(token, streamerId) {


    var body = {
      token: token,
      streamerId: streamerId,
      contestId:"8c15c6fd-a018-4133-b0f5-537345698bf2"
    }
    console.log("turretVoteVoterInfo",body)

    return apiPostPromiseUnauth(body, apiPaths.turretVoteVote)

}

export function turretVoteJoinWaitlist(token, streamerId, joinWaitlist) {


  var body = {
    token: token,
    streamerId: streamerId,
    contestId:"8c15c6fd-a018-4133-b0f5-537345698bf2",
    didJoinWaitlist: joinWaitlist
  }
  console.log("turretVoteJoinWaitlist",body)

  return apiPostPromiseUnauth(body, apiPaths.turretVoteJoinWaitlist)

}


export function turretVoteGetVoterInfo(token,email="") {


    var body = {
      token: token,
      contestId:"8c15c6fd-a018-4133-b0f5-537345698bf2",
      email: email
    }
    console.log("turretVoteVoterInfo",body)

    return apiPostPromiseUnauth(body, apiPaths.turretVoteVoterInfo)

}


export function subscribeToList(email,twitchUsername) {


    var body = {
      twitchUsername:twitchUsername,
      email: email
    }
    console.log("subscribeToList",body)

    return apiPostPromiseUnauth(body, apiPaths.subscribeToList)

}


export function turretStoreGetDepositLink(token,id,productId,priceId) {


    var body = {
      token: token,
      product: "nothing",
      productId: productId,
      priceId: priceId,
      id: id
    }
    console.log("turretStoreGetDepositLink",body)

    return apiPostPromiseUnauth(body, apiPaths.turretStoreGetDepositLink)

}


export function roverEnvironmentSetupPubsub(roverEnvironmentId) {
  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      roverEnvironmentId: roverEnvironmentId,
      userId: userId
    }

    return apiPostPromise(body, apiPaths.roverEnvironmentSetupPubsub, true)
    .then((data) => {
      console.log("roverEnvironmentSetupPubsub",data)
      return {success: true}
    }).catch((error) => {
      return {success: false}
    })
  })
}

export function joinRoverEnvironmentWaitlist(roverEnvironmentId) {
  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      roverEnvironmentId: roverEnvironmentId,
      userId: userId,
      username: username
    }

    return apiPostPromise(body, apiPaths.joinRoverEnvironmentWaitlist)
    .then((data) => {
      console.log("updated rover environment",data)
      return {success: true}
    }).catch((error) => {
      return {success: false}
    })
  })
}

export function updateRoverEnvironment(roverId, environmentId) {
  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      roverId: roverId,
      environmentId: environmentId
    }

    return apiPostPromise(body, apiPaths.updateRoverEnvironment)
    .then((data) => {
      console.log("updated rover environment",data)
      return {success: true}
    }).catch((error) => {
      return {success: false}
    })
  })
}

export function addRover(roverId,keyHash) {

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      roverId: roverId,
      keyHash: keyHash,
      userId: userId,
      username: username
    }

    return apiPostPromise(body, apiPaths.addRover)
    .then((data) => {
      console.log("created new rover",data)
      return {success: true}
    }).catch((error) => {
      return {success: false}
    })
  })

}

export function connectToRoverMsg(roverId) {

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      roverId: roverId,
      userId: userId
    }

    return apiPostPromise(body, apiPaths.connectToRoverMsg, true)
    .then((data) => {
      console.log("msg approved",data)
      return {success: true}
    }).catch((error) => {
      return {success: false}
    })
  })

}

export function newCreatorAccount() {

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username

    var body = {
      userId: userId
    }

    return apiPostPromise(body, apiPaths.newCreatorAccount)
    .then((data) => {
      console.log("newCreatorAccount",data)
      return {
        success: true,
        url: data.url,
        object: data.object
      }
    }).catch((error) => {
      return {
        success: true,
        url: null,
        object: null
      }
    })
  })

}

export function purchaseTrial(productId) {

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      productId: productId,
      userId: userId
    }

    return apiPostPromise(body, apiPaths.purchaseTrial)
    .then((data) => {
      console.log("purchase Trial",data.secret)
      return Promise.resolve({
        success: true
      })
    }).catch((error) => {
      return Promise.resolve({success: false})
    })
  })

}

export function getPaymentLink(productId) {

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      productId: productId,
      userId: userId
    }

    return apiPostPromise(body, apiPaths.purchaselink)
    .then((data) => {
      console.log("purhcaselink",data.secret)
      return Promise.resolve({
        success: true,
        url:data.secret
      })
    }).catch((error) => {
      return Promise.resolve({success: false})
    })
  })

}

export function getPaymentIntent(productId) {

  return Auth.currentAuthenticatedUser().then((signedInUser) => {
    const userId = signedInUser.username
    const username = signedInUser.attributes.preferred_username
    var body = {
      productId: productId,
      userId: userId
    }

    return apiPostPromise(body, apiPaths.paymentIntent)
    .then((data) => {
      console.log("paymentIntent",data)
      return {success: true}
    }).catch((error) => {
      return {success: false}
    })
  })

}


export function apiPostPromiseUnauth(body, apiPath, addIdentityId = false) {

  const apiName = "rover"

  // const apiInit = {
  //       body: body,
  //       headers: {
  //         'Content-Type' : 'application/json',
  //       }
  //   };

    const apiInit = {
        body: body,
    };

  return API.post(apiName, apiPath, apiInit)

}


export function apiPostPromise(body, apiPath, addIdentityId = false) {

  const apiName = "rover"

  return Promise.all([Auth.currentSession(), Auth.currentUserCredentials(), Auth.currentAuthenticatedUser()])
  .then((res) => {
    // let accessToken = res[0].getAccessToken()
    let accessToken = res[0].getIdToken()
    // console.log("authUser",res[2])
    // let jwt = res[2].signInUserSession.idToken.jwtToken
    let jwt = accessToken.getJwtToken()
    let identityId = res[1].identityId

    if (addIdentityId) {
      body["identityId"] = identityId
    }

    const apiInit = {
        body: body,
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type' : 'application/json',
        }
    };

    // const apiInit = {
    //     body: body,
    // };

    return API.post(apiName, apiPath, apiInit)

  })

}
