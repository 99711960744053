import { useSearchParams } from 'react-router-dom';

export function TreatBotAppAuthView(props) {
    const [searchParams] = useSearchParams();

    // Converting searchParams to a JavaScript object (dictionary)
    const params = {};
    for (const [key, value] of searchParams.entries()) {
        params[key] = value;
    }

    // Function to copy the code to clipboard
    const copyToClipboard = () => {
        navigator.clipboard.writeText(params.code);
    }

    return (
        <div className="col-4 min-vh-100 d-flex flex-column align-items-left justify-content-left my-5 mx-5">
            <div className="mb-3 text-start store-title-bold">
                Copy This Code and Paste it in the Treat Bot App
            </div>
            <div className="mb-3 p-2 border rounded text-start" style={{ width: '100%', maxWidth: '300px', wordBreak: 'break-all' }}>
                {params.code}
            </div>
            <button className="btn btn-primary" onClick={copyToClipboard}>Copy Code</button>
        </div>
    )
}
