import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import {Link, useLocation} from 'react-router-dom';
import { PubSub } from 'aws-amplify';
import {Amplify} from '@aws-amplify/core';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { AutoTextSize } from 'auto-text-size'
import ProgressBar from './ProgressBar.js'
import { generateClient } from '@aws-amplify/api';
// import * as subscriptions from '../graphql/subscriptions';
import { API } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api';
import { getTreatBoss } from '../graphql/queries';
import { propTypes } from 'react-bootstrap/esm/Image.js';


export function TreatbotProgressView(props) {

  const location = useLocation();

    const [bitsProgress, setBitsProgress] = useState(0);
    const [bitsTarget, setBitsTarget] = useState(100);

    const [treatBossHealth, setTreatBossHealth] = useState(100)
    const [treatBossTotal, setTreatBossTotal] = useState(100);
    const [treatBossName, setTreatBossName] = useState("treatboss");


  useEffect(() => {
      Amplify.addPluggable(new AWSIoTProvider({
        aws_pubsub_region: 'us-east-2',
        aws_pubsub_endpoint: 'wss://a14f86uvpchkbq-ats.iot.us-east-2.amazonaws.com/mqtt',
      }));

      var robotId = location.pathname.split("/").pop()
      fetchTreatBoss(robotId)
      


    
    subscribeToUserFiredUpdates(robotId);
  }, [location]);

  function fetchTreatBoss(robotId) {

    var treatBossQuery = { query: getTreatBoss, variables: { roverId: robotId } }
      treatBossQuery["authMode"] = 'AWS_IAM'

      return API.graphql(treatBossQuery)
      .catch(err => {
        console.log(err)
        // console.log(err.data.getTreatBoss)
        // if (err.data.listRovers.items.length > 0) {
        //   return Promise.resolve(err)
        // } else {
        //   throw(err)
        // }
      }).then(res => {
        console.log("res",res)
        const data = res.data.getTreatBoss
        if (data == null) {
          return
        }
        if (data.bitsTreatProgress != null) {
          setBitsProgress(data.bitsTreatProgress)
        }
        if (data.bitsPerTreat != null) {
          setBitsTarget(data.bitsPerTreat)
        }

        if (data.currentHealth != null) {
          setTreatBossHealth(data.currentHealth)
        }
        
        if (data.totalHealth != null) {
        
          setTreatBossTotal(data.totalHealth)

        }

        if (data.username != null) {
          setTreatBossName(data.username)
        }

        return
      })

   

  }


  // useEffect(() => {
  //   const robotId = location.pathname.split("/").pop();
  //   const subscription = API.graphql(
  //     graphqlOperation(subscriptions.onUpdateTreatBoss, { id: robotId })
  //   ).subscribe({
  //     next: ({ value }) => {
  //       console.log('Subscription fired', value);
  //       const { data } = value;
  //       const treatBoss = data.onUpdateTreatBoss;

  //       if (treatBoss) {
  //         setTreatBossHealth(treatBoss.currentHealth);
  //         setTreatBossTotal(treatBoss.totalHealth);
  //         setTreatBossName(treatBoss.username);
  //       }
  //     },
  //     error: error => console.error('Subscription error', error),
  //     complete: () => console.log('Subscription complete'),
  //   });

  //   // Cleanup subscription on component unmount
  //   return () => subscription.unsubscribe();
  // }, [location.pathname]);


  function subscribeToUserFiredUpdates(robotId) {

    

    return PubSub.subscribe("progress-update/" + String(robotId) + "/+").subscribe({
        next: data => {
          console.log('Message received', data)

            if (data.value.progress != null) {
              setBitsProgress(data.value.progress)
            }

            if (data.value.activation != null) {
              setBitsTarget(data.value.activation)
            }

            if (data.value.currentHealth != null) {
                console.log("data.value.currentHealth",data.value.currentHealth)
                setTreatBossHealth(data.value.currentHealth)
            }

            if (data.value.totalHealth != null) {
                console.log("data.value.totalHealth",data.value.totalHealth)
              setTreatBossTotal(data.value.totalHealth)
            }

            if (data.value.username != null) {
              console.log("data.value.username",data.value.username)
              setTreatBossName(data.value.username)
            }
        },
        error: error => console.error("[FIRE SUB]",error),
        complete: () => console.log("[FIRE SUB]",'Done'),
      });
  }

  
  return (
    <div className="col-12 min-vh-100 justify-content-center align-items-center">

            {(props.progressType == "treatBoss" || props.progressType == "treatBossAndProgress") && <ProgressBar label={treatBossName} count={treatBossHealth} total={treatBossTotal} color1="green" color2="red" text1="white" text2="white" displayType="fraction"/>}
            {(props.progressType == "treatProgress" || props.progressType == "treatBossAndProgress" )&& <ProgressBar label="Next Treat:" count={bitsProgress} total={bitsTarget} color1="#A379C9" color2="#F1E4F3" text1="#470063" text2="white" displayType="remaining" units="Bits"/>}

    </div>
  )

}