import { useState, useEffect } from 'react';


export default function ProgressBar(props) {



  // const [count,updateCount] = useState(props.count)
  // const [total,updateTotal] = useState(props.total)
  const [pct,updatePct] = useState(0)
  const [pctRemain,updatePctRemain] = useState(100)


  useEffect(() => {
    var percent = (props.count / props.total) * 100
    var percentRemain = Math.max(((props.total - props.count) / props.total) * 100,0)

    updatePct(percent)
    updatePctRemain(percentRemain)

  },[])


  useEffect(() => {
    
    var percent = (Number(props.count) / Number(props.total)) * 100
    var percentRemain = Math.max(((props.total - props.count) / props.total) * 100,0)
    updatePct(percent)
    updatePctRemain(percentRemain)

  },[props.count, props.total])


  const overlayTextStyle = {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '16px',
    color: props.text1, // Adjust the color so it's visible on all backgrounds
    top: '50%', // Center vertically
    left: '50%', // Center horizontally
    transform: 'translate(-50%, -50%)', // Adjust for exact centering
  };

  return (
     <div class="row">

        {/* <div class="col-3 text-start" style={{"color":"white","font-weight":"bold","font-size":11}}>
          {props.label}
        </div> */}
              
        <div class="col-12">

        <div class="progress" style={{height: '100%',border:"0",borderRadius:"0",position:"relative",widthd:"100%"}}>
          {props.displayType == "fraction" && <div style={overlayTextStyle}>{props.label} {props.count} / {props.total}</div>}
          {props.displayType == "remaining" && <div style={overlayTextStyle}>{props.label} {Math.max(props.total - props.count,0)} {props.units}</div>}
          <div class="progress-bar" role="progressbar" style={{width: pct + "%","font-size":11, "backgroundColor":props.color1,color:props.text1,"font-weight":"bold","height":"40px"}} aria-valuemin="0" aria-valuemax="100" id="progressLabel">
            {/* {props.displayType == "fraction" && pct > 60 && <div class="my-1"> {props.label} {props.count} / {props.total}</div>}
            {props.displayType == "fraction" && pct > 30 && pct < 60 && <div class="my-1"> {props.label}</div>}
            {props.displayType == "remaining" && pct > 40 && <div class="my-1">{props.label} {Math.max(props.total - props.count,0)} {props.units}</div>} */}
          </div>

          <div class="progress-bar" role="progressbar" style={{width:pctRemain + "%","backgroundColor":props.color2,color:props.text2,"font-size":11,"font-weight":"bold","height":"40px"}} aria-valuemin="0" aria-valuemax="100" id="progressRemainingLabel">
              {/* {props.displayType == "fraction" && pct <= 30 && <div class="my-1">{props.label} {props.count} / {props.total}</div>}
              {props.displayType == "fraction" && pct > 30 && pct < 60  && <div class="my-1">{props.count} / {props.total}</div>}
              {props.displayType == "remaining" && pct <= 40 && <div class="my-1">{props.label} {Math.max(props.total - props.count,0)} {props.units}</div>} */}
          </div>
        </div>
      </div>

    </div>
  )
}
