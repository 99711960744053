import { useState, useEffect } from 'react';
import {Link, useLocation} from 'react-router-dom';
import { PubSub } from 'aws-amplify';
import {Amplify} from '@aws-amplify/core';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { AutoTextSize } from 'auto-text-size'

export function TurretFiredImageView(props) {

  const location = useLocation();

  // const [broadcasterId, setBroadcasterId] = useState("");
  const [displayMessage, setDisplayMessage] = useState(null);
  const [displayImgURL, setDisplayImgURL] = useState(null);
  useEffect(() => {
    Amplify.addPluggable(new AWSIoTProvider({
       aws_pubsub_region: 'us-east-2',
       aws_pubsub_endpoint: 'wss://a14f86uvpchkbq-ats.iot.us-east-2.amazonaws.com/mqtt',
     }));

    var broadcasterId = location.pathname.split("/").pop()
    subscribeToUserFiredUpdates(broadcasterId);
  }, []);


  function subscribeToUserFiredUpdates(twitchChannelId) {

    

    return PubSub.subscribe("user-fired/" + String(twitchChannelId) + "/+").subscribe({
        next: data => {
          console.log("[FIRE SUB]",'Message received', data)
          setDisplayMessage(data.value.displayMessage)
          setDisplayImgURL(data.value.displayImgURL)
          setTimeout(function(){
            setDisplayMessage(null)
          }, 30000);
        },
        error: error => console.error("[FIRE SUB]",error),
        complete: () => console.log("[FIRE SUB]",'Done'),
      });
  }

  
  return (
    <div className="col-12 d-flex min-vh-100 justify-content-center align-items-center">

    {(displayMessage && 
          // <h5 style={{color:"#33FF33"}} bold="true">{displayName} fired Stream Turret!!!</h5>
        <div className="row justify-content-center">

          <div className="col">
              <div className="row justify-content-center">
               <div className="col">
                  <img className="rotating2 treatbot-animation-img" src={displayImgURL}/>
                </div>
              </div>
              {/* <div className="row justify-content-center">
               <div className="col">
                  <img className="rotating2 treatbot-animation-img" src={displayImgURL}/>
                </div>
              </div> */}
          </div>


          <div className="col">
              <p className='treatbot-animation'>{displayMessage}</p>
          </div>
          

          <div className="col">
              <div className="row justify-content-center">
               <div className="col">
                  <img className="rotating2 treatbot-animation-img" src={displayImgURL}/>
                </div>
              </div>
              {/* <div className="row justify-content-center">
               <div className="col">
                  <img className="rotating2 treatbot-animation-img" src={displayImgURL}/>
                </div>
              </div> */}
          </div>

          

      </div>
          )
    }
    

    </div>
  )

  // return (
  //   <div className="col-12 d-flex min-vh-100 justify-content-center align-items-center">

  //   {(displayMessage && 
  //         // <h5 style={{color:"#33FF33"}} bold="true">{displayName} fired Stream Turret!!!</h5>
  //       <div  >
  //         {/* <AutoTextSize> */}
  //         <div className="row justify-content-center">
  //           <div className="col-6">
  //             <img className="rotating2" src={displayImgURL} style={{"height":"200px"}}/>
  //           </div>
  //           <div className="col-6">
  //             <img className="rotating" src={displayImgURL} style={{"height":"200px"}}/>
  //           </div>
  //         </div>

  //         <div className="row">
  //           <div className="col-12">
              
  //               <p className='treatbot-animation'>{displayMessage}</p>
              
  //           </div>
  //         </div>

  //         <div className="row justify-content-center">
  //           <div className="col-6">
  //             <img className="rotating" src={displayImgURL} style={{"height":"200px"}}/>
  //           </div>
  //           <div className="col-6">
  //             <img className="rotating2" src={displayImgURL} style={{"height":"200px"}}/>
  //           </div>
  //         </div>

  //         {/* </AutoTextSize> */}

  //     </div>
  //         )
  //   }
    

  //   </div>
  // )

}